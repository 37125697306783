import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

var featureCarousel = new Swiper(".feature-carousel", {
  slidesPerView: "1.5",
  centeredSlides: true,
  spaceBetween: 16,
  loop: true,
  navigation: {
    prevEl: ".swiper-prev",
    nextEl: ".swiper-next",
  },
  breakpoints: {
    1280: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    2160: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
  },
});

var newsCarousel = new Swiper(".news-carousel", {
  slidesPerView: "1.5",
  spaceBetween: 24,
  loop: false,
  slidesOffsetBefore: 24,
  slidesOffsetAfter: 24,
  navigation: {
    prevEl: ".news-swiper-prev",
    nextEl: ".news-swiper-next",
  },
  breakpoints: {
    768: {
      slidesPerView: "2.5",
      spaceBetween: 24,
      slidesOffsetBefore: 64,
      slidesOffsetAfter: 64,
    },
    1024: {
      slidesPerView: "2.75",
      spaceBetween: 24,
      slidesOffsetBefore: 96,
      slidesOffsetAfter: 96,
    },
    1280: {
      slidesPerView: 3,
      spaceBetween: 40,
      slidesOffsetBefore: 96,
      slidesOffsetAfter: 96,
    },
    1600: {
      slidesPerView: 4,
      spaceBetween: 40,
      slidesOffsetBefore: 160,
      slidesOffsetAfter: 160,
    },
    1920: {
      slidesPerView: 4,
      spaceBetween: 40,
      slidesOffsetBefore: 192,
      slidesOffsetAfter: 192,
    },
  },
});
